

$.fn.openSelect = function() {
  return this.each(function(idx, ele) {
    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      ele.dispatchEvent(event);
    } else if (element.fireEvent) {
      ele.fireEvent("onmousedown");
    }
  });
}

function labelForm() {
  $('label').each(function(idx, ele){
    if ($(ele).data('type') == 'select') {
      $(ele).on('click', function (e) {
        var sel = '#' + $(ele).attr('for');
        $(sel).openSelect();
      });
    }
  });
}

function putForm(form) {
  resetForm(form);
  processForm(form, 'put');
}

function postForm(form) {
  resetForm(form);
  processForm(form, 'post');
}

function restoreForm(form) {
  form.find('input, select, textarea').each(function(idx, ele){
    $(ele).val($(ele).data('orig'));
  });
}

function resetForm(form) {
  form.find('.notification').addClass('d-none');
  form.find('.is-invalid').removeClass('is-invalid');
  form.find('.feedback').addClass('d-none');
}

function processForm(form, method) {
  var payload, param, status, data, headers;
  var url = $(form).data('route');

  payload = form.serializeJSON();

  axios[method](url, payload)
  .then(function (response) {
    status = response.status;
    data = response.data;
    switch(status) {
      case 204:
        form.find('.success-notification').removeClass('d-none');
        form.find('input, select, textarea').each(function(idx, ele){
          $(ele).data('orig', $(ele).val());
        });
        break;
    }
    if (response.headers.redirect) {
      window.location.href = response.headers.redirect;
    }
  })
  .catch(function (error) {
    status = error.response.status;
    data = error.response.data;
    switch(status) {
      case 422:
        var keys = Object.keys(data);
        for(i=0,l=keys.length;i<l;i++){
          form.find('#' + keys[i]).addClass('is-invalid').parent().find('.422').removeClass('d-none');
        }
        form.find('.error-notification').removeClass('d-none');
        break;
      case 500:
        console.log(data);
      default:
        console.log(data);
    }
  });
}
